<template>
  <div class="video-container">
    <video controls>
      <source :src="file.path" type="video/mp4" />
      <source :src="file.path" type="video/ogg" />
      Sorry, your browser doesn't support embedded videos.
    </video>
  </div>
</template>

<script>
export default {
  props: { file: { required: true } },
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>

<style scoped></style>

<style lang="scss">
video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
}
.file-view-container {
  width: 75vw;
  height: 75vh;
}

div.video-container {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
